<template>
  <div class="hello" align="center">
    <div class="headss">
      <div class="hls">
        <img class="logo" src="../assets/img/logo.png">
        <div class="line"></div>
        <span class="title">将 繁 琐 的 工 作 交 给 它</span>
        <span class="btn" @click="homes">首页</span>
        <span class="btn1" title="点击下载客户端" @click="xzApp">客户端下载</span>
        <span class="btnan" @click="InstalTutorial">安装教程</span>
        <!-- <span class="btn2">产品说明</span> -->
      </div>
      <div class="hgs">
        <img class="phone" src="../assets/img/phone.png">
        <span class="btn3" style="margin-left: 1vh">188 5714 3527</span>
        <div class="testUse" @click="applyTrial">申请试用></div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="introduceCss">
      <br>
      <br>
      <el-carousel :interval="4000" type="card" height="700px">
        <!-- <el-carousel-item v-for="item in 6" :key="item">
          <h3 class="medium">{{ item }}</h3>
        </el-carousel-item> -->
        <el-carousel-item >
          <img src="../assets/introduce/1.jpg" alt="">
        </el-carousel-item>    
        <el-carousel-item >
          <img src="../assets/introduce/2.jpg" alt="">
        </el-carousel-item>     
        <el-carousel-item >
          <img src="../assets/introduce/3.jpg" alt="">
        </el-carousel-item>    
        <el-carousel-item >
          <img src="../assets/introduce/4.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/5.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/6.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/7.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/8.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/9.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/10.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../assets/introduce/11.jpg" alt="">
        </el-carousel-item>
      </el-carousel>
      <!-- <div>
        <img src="../assets/introduce/1.jpg" alt="">
        <img src="../assets/introduce/2.jpg" alt="">
        <img src="../assets/introduce/3.jpg" alt="">
        <img src="../assets/introduce/4.jpg" alt="">
        <img src="../assets/introduce/5.jpg" alt="">
        <img src="../assets/introduce/6.jpg" alt="">
        <img src="../assets/introduce/7.jpg" alt="">
        <img src="../assets/introduce/8.jpg" alt="">
        <img src="../assets/introduce/9.jpg" alt="">
        <img src="../assets/introduce/10.jpg" alt="">
        <img src="../assets/introduce/11.jpg" alt="">
      </div> -->

    </div>

    <div class="content">
      <!-- 四张图片 -->
      <!-- <div class="endRow">
        <p class="title">
          <span style="color: #0b4afa">行业新帮手&nbsp;</span>
          <span>&nbsp;他们正在用</span>
        </p>
        <div class="image-container">
          <img src="../assets/img/one.png" alt="Image 1">
          <img src="../assets/img/two.png" alt="Image 2">
          <img src="../assets/img/three.png" alt="Image 3">
          <img src="../assets/img/four.png" alt="Image 4">
        </div>
      </div> -->
      <!-- 底部黑色背景内容部分 -->
      <div class="bottomContent">
        <!-- 左边 -->
        <div class="contleft">
          <div><img src="../assets/img/pcbuttonlogo.png" alt=""></div>
          <br>
          <div>2020 云内密码 (杭州) 软件有限公司</div>
          <div>浙ICP备2023031983号</div>
          <div>版权所有 盗版必究</div>
        </div>
        <!-- 右边 -->
        <div class="contright">
          <div>
            <div>产品服务</div>
            <br>
            <div>官网下载</div>
            <div>功能扩展</div>
            <div>量身定制</div>
          </div>
          <div>
            <div>关于我们</div>
            <br>
            <div>服务时间</div>
            <div>09:00-18:00工作日</div>
          </div>
          <div>
            <div>&nbsp;客服咨询</div>
            <br>
            <div><img src="../assets/img/wxservice.png" alt=""></div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="客服微信"
      :visible.sync="dialogVisible"
      width="25%"
      >
      <div><img src="../assets/img/wxservice.png" alt=""></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { downloadApp } from "@/api/index"
// import MyDialog from '@/component/myDiaLog.vue';
// import VideoPlayer from "@/component/VideoPlayer.vue";
// import InstallVideo from "@/component/installVideo.vue";

export default {
  name: 'HelloWorld',
  components: {
    // VideoPlayer, // 注册视频组件
    // InstallVideo // 安装视频组件
  //   MyDialog,
  },
  data() {
    return {
      // isShow: true,
      isShow:false,
      videoShow: false, // 安装教程
      dialogVisible: false,
      // img1: require('@/assets/introduce/1.jpg')
      
    }
  },
  methods: {
    homes() {
      console.log('12312');
      this.$router.push({name: 'HelloWorld'})
    },
    // 点击产品说明
    // Product() {
    //   console.log('点击了产品说明');
    //   this.$router.push({name: 'prad'})
    // },
    // 点击安装教程
    InstalTutorial() {
      console.log('点击了安装教程啊7777');
      this.$router.push({name: 'installVideo'})
    },
    // 弹窗
    applyTrial() {
      this.dialogVisible = true;
    },
    async xzApp() {
      console.log('下载app');
      const { data: res } = await downloadApp();
      // console.log(res, "数据");
      // console.log(res.data.url, "url地址");
      const downloadLink = res.data.url;
      window.location.href = downloadLink;
      this.$message('下载中,请留意浏览器下载文件!');
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.hello {
  width: 100%;
  user-select: none; // 不可选中文本信息
  .headss {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 12vh;
    background-color: rgb(11, 74, 250);
    .hls {
      width: 50%;
      min-width: 580px;
      margin: 10px;
      // background-color: #13c248;
      display: flex;
      span {
        color: #FFFFFF;
        font: "思源黑体 CN Medium";
        font-size: 13px;
        line-height: 12vh;
        margin-left: 40px;
        cursor:pointer;
        user-select: none;
      }
      // log
      .logo {
          height: 40%;
          margin-top: 4vh;
          margin-left: 3vw;
          display: inline-block;
        }
      // 竖线
      .line {
        width: 1%;
        height: 4vh;
        margin-top: 4vh;
        margin-left: 1vh;
        display: inline-block;
        border-right: solid #FFFFFF 1px;
      }
      .btn {
        min-width: 26px;
      }
      .btn1 {
        min-width: 65px;
      }
      .btnan {
        min-width: 52px;
      }     
       .btn2 {
        min-width: 52px;
      }
      // .min-width: 80px; 
      // 标题
      .title {
        font-size: 24px;
        min-width: 270px;
      }

    }
    .hgs {
      width: 50%;
      min-width: 580px;
      margin: 10px;
      // background-color: pink;
      display: flex;
      justify-content: flex-end;
      .phone {
        margin-left: 50%;
        margin-top: 4.5vh;
        width: 20px;
        height: 26px;
      }
      .btn3 {
        color: #FFFFFF;
        font: "思源黑体 CN Medium";
        font-size: 13px;
        line-height: 12vh;
        margin-left: 2vh;
        display:inline-block;
        width: 100px;
        min-width: 100px;
      }
      .testUse {
        cursor:pointer;
        margin-top: 4vh;
        height: 16px;
        line-height: 16px;
        border: #FFFFFF 1px solid;
        color: #FFFFFF;
        font: "思源黑体 CN Medium";
        font-size: 16px;
        padding: 1vh;
        margin-left: 2vh;
        display:inline-block;
        min-width: 80px;
        margin-right: 80px;
      }
    }

  }
  // 产品介绍
  
  .introduceCss {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    img {
      max-width: 100%;
      max-height: 100%;
      // width: 48%; /* 设置图片宽度，可以根据实际需求调整 */
    }
    // img:nth-child(odd) {
    //   // order: 1;
    //   margin-top: 10px;
    // }
    // img:nth-child(even) {
    //   // order: 2;
    //   margin-top: 20px;
    // }
  }
}

.content {
  width: 100%;
  height: auto;
  border-top: #FFFFFF solid 2px;


  .test-enter, .test-leave-to {
    transform: translateX(120%);
  }

  .test-enter-active, .test-leave-active {
    transition: transform 3s linear;
  }

  .test-enter-to, .test-leave {
    transform: translateX(0%);
  }


  .numEmp {
    margin-top: 10vh;
    height: 40vh;
    background-image: url("../assets/img/empBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .boxx {
      img {
        height: 40px;
        transition: transform 0.3s ease;
      }
      img:hover {
          transform: translate(0, -15px);
        }
      .left-top {
      position: absolute;
      top: -50px;
      left: -47px;
      // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
    }
    .left-z {
      position: absolute;
      top: 155px;
      left: -28px;
      // background-color: red; /* 设置盒子的背景颜色，根据需要进行调整 */

    }
    .left-bottom {
        position: absolute;
        bottom: -47px;
        left: -51px;
        // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
      }
      .right-bottom {
        position: absolute;
        bottom: -50px;
        right: -47px;
        // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
      }
      .right-top {
        position: absolute;
        top: -50px;
        right: -47px;
        // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
      }
      .right-z {
        position: absolute;
        top: 155px;
        right: -48px;
        // background-color: pink;
      }

    }
  }

  .endRow {
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 1vw;
    .title {
      margin-bottom: 0;
      font-size: 45px;
      // display:inline-block;
      // width: 300px;
      font: "思源黑体 CN bold";
    }
    // 图片x4
    .image-container {
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      height: 100vh; /* 设置容器高度为视口高度，可根据需要调整 */
      img {
        max-width: 100%; /* 图片最大宽度为容器宽度 */
        max-height: 100%;
        transition: transform 0.3s ease;
      }
      img:hover {
        transform: translate(0, -15px);
      }
    }
  }
  // 底部
  .bottomContent {
    width: 100%;
    height: 230px;
    margin-top: 1vw;
    margin-bottom: 1vw;
    display: flex;
    color: #f5f5f5f5;
    font-size: 14px;
    background-color: #131b32;
    // 左
    .contleft {
      width: 100%;
      height: 200px;
      margin-top: 2vw;
      div {
        width: 270px;
        margin-top: 4px;
        text-align: left;
        cursor: pointer;

      }

    }
    // 右
    .contright {
      width: 100%;
      height: 200px;
      margin-top: 15px;
      margin-top: 2vw;
      display: flex;
      // background-color: red;
      div {
        flex: 1;
        margin-top: 4px;
        text-align: left;
        cursor: pointer;
        img {
          width: 80px;
          height: 80px;
        }
      }

    }

  }

}
</style>
