import request1 from "@/utils/request.js";

// APP配置功能管理

// 下载app请求
export function downloadApp() {
  return request1({
    url: `/official/download`,
    method: "get",
    // params: data,
  });
}