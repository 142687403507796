<template>
  <div >
    <div v-if="videoSource">
      <video class="videostyle" controls muted>
      <source :src="videoSource" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
    <div v-else>
      Loading video...
    </div>
  </div>
</template>

<script>
import { downloadApp } from "@/api/index"

export default {
  data() {
    return {
      // videoSource: require('@/assets/videos/xiaomi.mp4')
      videoSource: null
    };
  },
  created() {
    this.getLink()
  },
  methods: {
    async getLink () {
      const { data: res } = await downloadApp();
      console.log(res.data.future, '请求链接');
      this.videoSource = res.data.install
    }
  }
};
</script>

<style>
/* 在这里可以添加样式以控制视频的外观 */
.videostyle {
  width: 100%;
  height: 100%;
}
</style>