<template>
  <div class="hello" align="center">
    <!-- <div class="head">
      <img class="logo" src="../assets/img/logo.png">
      <div class="line"></div>
      <p class="title">将繁琐的工作交给它</p>
      <span class="btn">首页</span>
      <span class="btn">客户端下载</span>
      <span class="请btn">专属顾问</span>
      <img class="phone" src="../assets/img/phone.png">
      <span class="btn" style="margin-left: 1vh">188 5714 3527</span>
      <div class="testUse" @click="xzApp">申请试用></div>
    </div> -->
    <div class="headss">
      <div class="hls">
        <img class="logo" src="../assets/img/logo.png">
        <div class="line"></div>
        <span class="title">将 繁 琐 的 工 作 交 给 它</span>
        <!-- <span class="btn">首页</span> -->
        <span class="btn1" title="点击下载客户端" @click="xzApp">客户端下载</span>
        <span class="btnan" @click="InstalTutorial">安装教程</span>
        <span class="btn2" @click="Product">产品说明</span>
      </div>
      <div class="hgs">
        <img class="phone" src="../assets/img/phone.png">
        <span class="btn3" style="margin-left: 1vh">188 5714 3527</span>
        <div class="testUse" @click="applyTrial">申请试用></div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <!-- 测试动画效果 -->
      <!-- <div class="donghua">
        <h3 class="ani"
          swiper-animate-effect="ani-snail">1
        </h3>
      </div> -->
    <!-- 测试动画效果结束 -->
    <div class="content">
      <div class="firstRow">
        <div class="free">
          免费试用
        </div>
      </div>

      <div class="twoRow">
        <p class="twoTitle">
          <span style="color: #0b4afa">电商数字员工</span>
          <span>可以做什么？</span>
        </p>
        <p class="detail">接收指令，替您高效便捷地完成反复工作</p>
        <div class="twoMain">
          <div class="sddw">
            <img  src="../assets/img/robot.png">
            <div class="rypian"><img src="../assets/img/shoudao.png" alt=""></div>
          </div>
          <div>
            <!-- <div class="qaq"> qw  qw</div> -->
            <transition-group class="groupHover" style="margin-left: 5vw; margin-top: 5vh; display: flex; align-items: center;" name="test" appear>
            <div v-show="isShow" key="1" class="getCmd" style="display: flex; align-items: center;">
              <img style="position: relative; top: -3vh; margin-right: 5px;" src="../assets/img/userPic.png">
              <span><strong>：给我发送<br>&nbsp;&nbsp;&nbsp;&nbsp;一份竞品的报表</strong></span>
            </div>
          </transition-group>
          <transition-group class="groupHover" style="margin-left: 5vw; margin-top: 5vh; display: flex; align-items: center;" name="test" appear>
            <div v-show="isShow" key="1" class="getCmd" style="display: flex; align-items: center;">
              <img style="position: relative; top: -3vh; margin-right: 5px;" src="../assets/img/userPic.png">
              <span><strong>：帮我检查<br>&nbsp;&nbsp;&nbsp;&nbsp;一下店铺链接评价</strong></span>
            </div>
          </transition-group>
          <transition-group class="groupHover" style="margin-left: 5vw; margin-top: 5vh; display: flex; align-items: center;" name="test" appear>
            <div v-show="isShow" key="1" class="getCmd" style="display: flex; align-items: center;">
              <img style="position: relative; top: -3vh; margin-right: 5px;" src="../assets/img/userPic.png">
              <span><strong>：给我一份<br>&nbsp;&nbsp;&nbsp;&nbsp;昨天店铺数据报表</strong></span>
            </div>
          </transition-group>
          <transition-group class="groupHover" style="margin-left: 5vw; margin-top: 5vh; display: flex; align-items: center;" name="test" appear>
            <div v-show="isShow" key="1" class="getCmd" style="display: flex; align-items: center;">
              <img style="position: relative; top: -3vh; margin-right: 5px;" src="../assets/img/userPic.png">
              <span><strong>：帮我检查<br>&nbsp;&nbsp;&nbsp;&nbsp;一下店铺有无异常</strong></span>
            </div>
          </transition-group>
          <transition-group class="groupHover" style="margin-left: 5vw; margin-top: 5vh; display: flex; align-items: center;" name="test" appear>
            <div v-show="isShow" key="1" class="getCmd" style="display: flex; align-items: center;">
              <img style="position: relative; top: -3vh; margin-right: 5px;" src="../assets/img/userPic.png">
              <span><strong>：帮我推荐<br>&nbsp;&nbsp;&nbsp;&nbsp;几位合适的达人</strong></span>
            </div>
          </transition-group>
          </div>
          <!-- <transition-group style="margin-left: 5vw;margin-top: 5vh" name="test" appear>
            <div v-show="isShow" key="1" class="getCmd">
              <img style="position: absolute; top: 2vh;margin-left: -5vw" src="../assets/img/userPic.png">
        
              <div>：给我发送一份竞品报表</div>
              <span style="margin-top: 5px">：给我发送一份竞品报表</span>
            </div>
          </transition-group> -->
        </div>
      </div>

      <div class="wideoRow">
        <p class="videoTitle">学习进化型数字员工</p>
        <p class="videoContent">基于电商大模型的持续学习，能让数字员工不断解锁各种场景下的工作新技能</p>
        <p class="videoContent">实现对企业经营的全面助推。</p>
        <div class="qaq">
          <InstallVideo />
        </div>
      </div>

      <div class="twoRow">
        <p class="twoTitle">
          <span style="color: #0b4afa">“数字员工”&nbsp;</span>
          <span>&nbsp;核心优势</span>
        </p>
        <p class="detail">它从入职开始，就在不断自主学习，不仅越来越全面，还会更加适合你的店</p>
        <div class="numEmp">
          <img style="height: 80%" src="../assets/img/core.png">
          <div class="boxx">
            <div class="left-top"><img src="../assets/img/4.png" alt=""></div>
            <div class="left-z"><img src="../assets/img/6.png" alt=""></div>
            <div class="left-bottom"><img src="../assets/img/5.png" alt=""></div>
            <div class="right-top"><img src="../assets/img/1.png" alt=""></div>
            <div class="right-z"><img src="../assets/img/3.png" alt=""></div>
            <div class="right-bottom"><img src="../assets/img/2.png" alt=""></div>
          </div>

        </div>
      </div>
      <!-- 四张图片 -->
      <div class="endRow">
        <p class="title">
          <span style="color: #0b4afa">行业新帮手&nbsp;</span>
          <span>&nbsp;他们正在用</span>
        </p>
        <!-- 修改 -->
        <div class="image-container">
          <img src="../assets/img/one.png" alt="Image 1">
          <img src="../assets/img/two.png" alt="Image 2">
          <img src="../assets/img/three.png" alt="Image 3">
          <img src="../assets/img/four.png" alt="Image 4">
        </div>
        <!-- 修改结束 -->
        <!-- 老代码 -->
        <!-- <div style="width: 100%;margin-top: 5vh;display: flex">
          <img src="../assets/img/one.png">
          <img src="../assets/img/two.png">
          <img src="../assets/img/three.png">
          <img src="../assets/img/four.png">
          <img src="../assets/img/four.png">
        </div> -->
      </div>
      <!-- 底部黑色背景内容部分 -->
      <div class="bottomContent">
        <!-- 左边 -->
        <div class="contleft">
          <div><img src="../assets/img/pcbuttonlogo.png" alt=""></div>
          <br>
          <div>2020 云内密码 (杭州) 软件有限公司</div>
          <!-- <div>浙ICP备2023031983号</div> -->
          <div><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023031983号</a></div>
          <!-- <div><a href="https://www.baidu.com/"  target="_blank">浙ICP备2023031983号</a></div> -->
          <!-- <a href="http://www.cnblogs.com/sunyunh/" title="随心而动的博客！">我的博客</a> -->
          
          <div>版权所有 盗版必究</div>
        </div>
        <!-- 右边 -->
        <div class="contright">
          <div>
            <div>产品服务</div>
            <br>
            <div>官网下载</div>
            <div>功能扩展</div>
            <div>量身定制</div>
          </div>
          <div>
            <div>关于我们</div>
            <br>
            <div>服务时间</div>
            <div>09:00-18:00工作日</div>
          </div>
          <div>
            <div>&nbsp;客服咨询</div>
            <br>
            <div><img src="../assets/img/wxservice.png" alt=""></div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
        <!-- 弹窗 -->
      <el-dialog
      title="客服微信"
      :visible.sync="dialogVisible"
      width="25%"
      >
      <div><img src="../assets/img/wxservice.png" alt=""></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { downloadApp } from "@/api/index"
import InstallVideo from "@/component/installVideo.vue";

export default {
  name: 'HelloWorld',
  components: {
    InstallVideo
  },
  data() {
    return {
      isShow: true,
      dialogVisible: false
    }
  },
  methods: {
        // 点击产品说明
    Product() {
      console.log('点击了产品说明');
      this.$router.push({name: 'prad'})
    },
    // 点击安装教程
    async InstalTutorial() {
      console.log('点击了安装教程啊1111666');
      // this.$router.push({name: 'installVideo'})
      // 下载安装教程
      const { data: res } = await downloadApp();
      const installs = res.data.install;
      window.location.href = installs;

      
      // 结束

    },
    // 弹窗
    applyTrial() {
      this.dialogVisible = true;
    },
    async xzApp() {
      console.log('下载app');
      const { data: res } = await downloadApp();
      // console.log(res, "数据");
      // console.log(res.data.url, "url地址");
      const downloadLink = res.data.url;
      window.location.href = downloadLink;
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../style/home.less';
.hello {
  width: 100%;
  user-select: none; // 不可选中文本信息
  .headss {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 12vh;
    background-color: rgb(11, 74, 250);
    .hls {
      width: 50%;
      min-width: 580px;
      margin: 10px;
      // background-color: #13c248;
      display: flex;
      span {
        color: #FFFFFF;
        font: "思源黑体 CN Medium";
        font-size: 13px;
        line-height: 12vh;
        margin-left: 40px;
        cursor:pointer;
        user-select: none;
      }
      // log
      .logo {
          height: 40%;
          margin-top: 4vh;
          margin-left: 3vw;
          display: inline-block;
        }
      // 竖线
      .line {
        width: 1%;
        height: 4vh;
        margin-top: 4vh;
        margin-left: 1vh;
        display: inline-block;
        border-right: solid #FFFFFF 1px;
      }
      .btn {
        min-width: 26px;
      }
      .btn1 {
        min-width: 65px;
      }
      .btnan {
        min-width: 52px;
      }     
       .btn2 {
        min-width: 52px;
        z-index: 999;
      }
      // .min-width: 80px; 
      // 标题
      .title {
        font-size: 24px;
        min-width: 270px;
      }

    }
    .hgs {
      width: 30%;
      min-width: 580px;
      margin: 10px;
      // background-color: pink;
      display: flex;
      justify-content: flex-end;
      .phone {
        margin-left: 50%;
        margin-top: 4.5vh;
        width: 20px;
        height: 26px;
      }
      .btn3 {
        color: #FFFFFF;
        font: "思源黑体 CN Medium";
        font-size: 13px;
        line-height: 12vh;
        margin-left: 2vh;
        display:inline-block;
        width: 100px;
        min-width: 100px;
      }
      .testUse {
        cursor:pointer;
        margin-top: 4vh;
        height: 16px;
        line-height: 16px;
        border: #FFFFFF 1px solid;
        color: #FFFFFF;
        font: "思源黑体 CN Medium";
        font-size: 16px;
        padding: 1vh;
        margin-left: 2vh;
        display:inline-block;
        min-width: 80px;
        margin-right: 80px;
      }
    }

  }
}

.head {
  width: 100%;
  height: 12vh;
  background-color: rgb(11, 74, 250);
  display: flex;

  .logo {
    height: 55%;
    margin-top: 2.5vh;
    margin-left: 3vw;
  }

  .line {
    width: 1%;
    height: 8vh;
    margin-top: 2vh;
    margin-left: 1vh;
    border-right: solid #FFFFFF 1px;
  }

  .title {
    color: #FFFFFF;
    font: "思源黑体 CN bold";
    font-size: 26px;
    height: 12vh;
    line-height: 12vh;
    margin: 0 0 0 2vh;
  }

  .btn {
    color: #FFFFFF;
    font: "思源黑体 CN Medium";
    font-size: 13px;
    line-height: 12vh;
    margin-left: 2vh;
  }

  .phone {
    margin-left: 50%;
    margin-top: 4.8vh;
    width: 20px;
    height: 26px;
  }

  .testUse {
    margin-top: 4vh;
    height: 16px;
    line-height: 16px;
    border: #FFFFFF 1px solid;
    color: #FFFFFF;
    font: "思源黑体 CN Medium";
    font-size: 16px;
    padding: 1vh;
    margin-left: 2vh;
  }
}

.content {
  width: 100%;
  height: auto;
  border-top: #FFFFFF solid 2px;

  .firstRow {
    width: 100%;
    height: 80vh;
    background-image: url("../assets/img/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .free {
      position: absolute;
      margin-top: 55vh;
      margin-left: 10%;
      width: 8vw;
      height: 4vh;
      background-color: rgb(254, 0, 0);
      line-height: 4vh;
      color: #FFFFFF;
    }
    .videos {
      position: absolute;
      margin-top: 55vh;
      margin-left: 60%;
      width: 500px;
      height: auto;
      background-color: #fff;

    }
  }

  .twoRow {
    width: 62.5%;
    margin-top: 1vw;
    margin-bottom: 1vw;

    .twoTitle {
      margin-bottom: 0;
      font-size: 60px;
      font: "思源黑体 CN bold";
    }

    .detail {
      color: #414242;
      font-size: 32px;
      font: "思源黑体 CN Regular";
    }

    .twoMain {
      width: 100%;
      display: flex;
      .groupHover {
        transition: transform 0.3s ease;
      }
      .groupHover:hover {
          transform: translate(0, -15px);
        }
      
      // .#e0ebfd 
      // 收到定位
      .sddw {
        position: relative;
        display: inline-block;
        img {
          transition: transform 0.3s ease;
        }
        img:hover {
          transform: translate(0, -15px);
        }
        .rypian {
          position: absolute;
          top:0;
          left: 0;
        }
      }
      .getCmd{
        width: 419px;
        height: 157px;
        background-image: url("../assets/img/check.png");
        position: relative;
        img {
          width: 50px;
          height: 50px;
        }
        span {
          margin-top: -12px;
          font-size: 20px;
          text-align: left;
          position: relative;
        }
        
      }
    }

    .bubble-container {
      position: relative;
      max-width: 300px; /* 气泡框最大宽度 */
      background-color: #FFFFFF; /* 气泡框背景颜色 */
      color: #000000; /* 文字颜色 */
      border-radius: 10px; /* 圆角边框 */
      padding: 10px; /* 内边距 */
      margin: 10px; /* 外边距，可根据需要调整 */
      border: #e0ebfd solid 1px;
      display: flex;


    }

    .triangle1 {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid transparent; /* 左边透明 */
      border-bottom: 10px solid transparent; /* 右边透明 */
      border-right: 10px solid #e0ebfd; /* 底边为气泡框背景色 */
      left: -10px; /* 位于气泡框顶部 */
      top: 50%; /* 居中 */
      transform: translateY(-50%); /* 水平居中 */
    }
  }

  .test-enter, .test-leave-to {
    transform: translateX(120%);
  }

  .test-enter-active, .test-leave-active {
    transition: transform 3s linear;
  }

  .test-enter-to, .test-leave {
    transform: translateX(0%);
  }

  .wideoRow {
    width: 100%;
    height: 30vh;
    background-image: url("../assets/img/video.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 1vh;
    position: relative;
    .videoTitle {
      color: #FFFFFF;
      font: "思源黑体 CN bold";
      font-size: 50px;
    }

    .videoContent {
      color: #FFFFFF;
      font: "思源黑体 CN Regular";
      font-size: 20px;
    }
    .qaq {
      position: absolute; /* 使子元素绝对定位 */
      top: 50px; /* 相对于父元素顶部的偏移量 */
      left: 100px; /* 相对于父元素左侧的偏移量 */
      width: 600px;
      height: auto;
      background-color: #ff0000;
    }
  }

  .numEmp {
    margin-top: 10vh;
    height: 40vh;
    background-image: url("../assets/img/empBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .boxx {
      img {
        height: 40px;
        transition: transform 0.3s ease;
      }
      img:hover {
          transform: translate(0, -15px);
        }
      .left-top {
      position: absolute;
      top: -50px;
      left: -47px;
      // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
    }
    .left-z {
      position: absolute;
      top: 155px;
      left: -28px;
      // background-color: red; /* 设置盒子的背景颜色，根据需要进行调整 */

    }
    .left-bottom {
        position: absolute;
        bottom: -47px;
        left: -51px;
        // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
      }
      .right-bottom {
        position: absolute;
        bottom: -50px;
        right: -47px;
        // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
      }
      .right-top {
        position: absolute;
        top: -50px;
        right: -47px;
        // background-color: rgba(0, 0, 0, 0.5); /* 设置盒子的背景颜色，根据需要进行调整 */
      }
      .right-z {
        position: absolute;
        top: 155px;
        right: -48px;
        // background-color: pink;
      }

    }
  }

  .endRow {
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 1vw;
    .title {
      margin-bottom: 0;
      font-size: 45px;
      font: "思源黑体 CN bold";
    }
    // 图片x4
    .image-container {
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      height: 80vh; /* 设置容器高度为视口高度，可根据需要调整 */
      img {
        max-width: 100%; /* 图片最大宽度为容器宽度 */
        max-height: 100%;
        transition: transform 0.3s ease;
      }
      img:hover {
        transform: translate(0, -15px);
      }
    }
  }
  // 底部
  .bottomContent {
    width: 100%;
    height: 230px;
    margin-top: 1vw;
    margin-bottom: 1vw;
    display: flex;
    color: #f5f5f5f5;
    font-size: 14px;
    background-color: #131b32;
    // 左
    .contleft {
      width: 100%;
      height: 200px;
      margin-top: 2vw;
      div {
        width: 270px;
        margin-top: 4px;
        text-align: left;
        cursor: pointer;

      }

    }
    // 右
    .contright {
      width: 100%;
      height: 200px;
      margin-top: 15px;
      margin-top: 2vw;
      display: flex;
      // background-color: red;
      div {
        flex: 1;
        margin-top: 4px;
        text-align: left;
        cursor: pointer;
        img {
          width: 80px;
          height: 80px;
        }
      }

    }

  }



}
</style>
