import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from './components/HelloWorld.vue'
import ProductDescription from './components/ProductDescription.vue'
import installVideo from './components/installVideo.vue'

Vue.use(Router)

let router = new Router({
    routes: [
        {path: '/prad', name: 'prad', component: ProductDescription},
        {path: '/installVideo', name: 'installVideo', component: installVideo},
        {path: '/', name: 'HelloWorld', component: HelloWorld},
        // {path: '/login', name: 'Login', component: Login},
    ]
})

// 注册全局钩子，判断是否需要跳转到登录页面
// router.beforeEach((to, from, next) => {
//     to.matched.some((route) => {
//             next()
//     })
// })

export default router
