import axios from "axios";
const request = axios.create({
  baseURL: "/api", // 本地网址
  // baseURL: "https://www.ai-yn.com/api/", // 本地网址

  timeout: 100000,
});
// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    // console.log(config.url, "路由");
    // const token = sessionStorage.getItem("token");
    // config.headers.Authorization = sessionStorage.getItem("token");
    // NProgress.start();
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  (response) => {
    // console.log(response, "拦截器1");
    // const { code } = response.data;
    // const { msg } = response.data;
    // console.log(code, "拦截器");
    // 1
    // if (code === 401) {
    //   // 结束
    //   // Message.error(msg);
    // } else if (code === 500) {
    //   // Message.error(msg);
    // } else if (code === 999) {
    //   // Message.error(msg);
    // } else if (code === 997) {
    //   // Message.error(msg);
    //   localStorage.removeItem("tabPane");
    //   sessionStorage.removeItem("token");
    // }
    return response;
  },
  (error) => {
    console.log("捕获异常", error);
    return Promise.reject(error);
  }
);
export default request;